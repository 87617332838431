* {
	box-sizing: border-box;
	word-break: break-word;
}

html, body {
	height: 100%;
}

body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
}
