.App {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0em;
	> header {
		display: flex;
		padding: clamp(0.5em, 3vw, 2em);
		justify-content: center;
		img {
			max-width: 40em;
			width: 100%;
		}
	}
	> main {
		padding: clamp(0.5em, 3vw, 1em);
		max-width: 40em;
	}
}
